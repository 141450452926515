<template>
  <Form
    @submit="onSubmit"
    :class="classnames"
    v-slot="{ errors, isSubmitting }"
    :validation-schema="getFields"
  >
    <div class="summary_title_items_wrapper">
      <div class="flex w-full items-center gap-x-8 max-md:justify-between">
        <span class="summary_title">{{ props.title }}</span>
        <template v-if="props.disabled === 'false'">
          <button
            type="button"
            v-if="!summaryDataObject.isEditable"
            class="summary_edit_button border-dark_main text-dark_main dark:border-main dark:text-main hover:border-main dark:hover:text-background px-3 py-1.5 md:hidden"
            @click="openEdit()"
          >
            Edit {{ route.name == 'success' ? 'Profile' : 'ride' }}
          </button>
          <button
            :disabled="isSubmitting"
            type="submit"
            v-if="summaryDataObject.isEditable"
            class="summary_edit_button save min-w-max border-none px-3 py-1.5 md:hidden"
          >
            Confirm
          </button>
        </template>
      </div>
      <div class="flex w-full flex-row gap-x-[30px] max-md:flex-wrap md:gap-x-[60px]">
        <div
          class="!flex w-full flex-wrap justify-between gap-5 md:gap-x-6 md:gap-y-4 lg:grid lg:grid-flow-col lg:max-xl:grid-rows-2"
        >
          <div
            v-if="checkField('first_name')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0"
            :class="errors.first_name ? 'error' : ''"
          >
            <span class="summary_item_title">First Name:</span>
            <Field
              type="text"
              v-model="orderData.first_name"
              @beforeinput="utils.isLetter($event)"
              class="summary_item_input dark:bg-background max-w-full border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              maxlength="50"
              name="first_name"
              placeholder="First Name"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            v-if="checkField('last_name')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0 lg:col-start-2"
            :class="errors.last_name ? 'error' : ''"
          >
            <span class="summary_item_title">Last Name:</span>
            <Field
              type="text"
              v-model="orderData.last_name"
              @beforeinput="utils.isLetter($event)"
              class="summary_item_input dark:bg-background max-w-full border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="last_name"
              maxlength="50"
              placeholder="Last Name"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            v-if="checkField('email')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0"
            :class="errors.email ? 'error' : ''"
          >
            <span class="summary_item_title">Email:</span>
            <Field
              type="text"
              v-model="orderData.email"
              class="summary_item_input dark:bg-background max-w-full border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="email"
              placeholder="Email"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div v-if="checkField('phone')" class="summary_item_wrapper mb-0 h-max !max-w-none pb-0">
            <span class="summary_item_title">Phone:</span>
            <vue-tel-input
              v-model="orderData.phone"
              class="vue-tel-input summary_item_input dark:bg-background max-w-full bg-[#E8EDE8]/50"
              @country-changed="countryChanged"
              v-on:beforeinput="utils.isNumber($event)"
              v-bind="defaultSettingsVueTel"
              :inputOptions="{
                placeholder: 'Phone',
                styleClasses:
                  '!py-5 border disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]'
              }"
              :disabled="!summaryDataObject.isEditable"
            >
            </vue-tel-input>
          </div>
          <div
            v-if="checkField('pickup') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            :class="{ error: errors.pickup }"
          >
            <span class="summary_item_title">Pick Up Location:</span>

            <Field :name="fields.pickup.name" v-model="orderData.pickup" v-slot="{ field }">
              <vue-google-autocomplete
                @placechanged="getAddressDataSummary"
                @focus="focusInput($event, 'pickup')"
                @beforeinput="beforeInput($event, 'pickup')"
                classname="!truncate summary-edit_item_input summary_item_input searchAutocomplete"
                v-bind="field"
                :placeholder="fields.pickup.placeholder"
                :autoCompleteString="orderData.pickup"
                types=""
                id="summary_1_pickup"
                maxlength="230"
                :disabled="!summaryDataObject.isEditable"
              >
              </vue-google-autocomplete>
            </Field>
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            v-else-if="checkField('pickup') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Pick Up Location:</span>
            <p
              class="summary_item_input text-background w-full !truncate break-all !rounded-none !border-0 !p-0 !pb-2 dark:!text-white"
            >
              {{ orderData.pickup }}
            </p>
          </div>
          <div
            v-if="checkField('dropoff') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            :class="{ error: errors.dropoff }"
          >
            <span class="summary_item_title">Drop-Off Location:</span>
            <Field
              :name="fields.dropoff.name"
              v-model="orderData.dropoff"
              :value="orderData.dropoff"
              v-slot="{ field }"
            >
              <vue-google-autocomplete
                @placechanged="getAddressDataSummary"
                @focus="focusInput($event, 'dropoff')"
                @beforeinput="beforeInput($event, 'dropoff')"
                classname="!truncate summary-edit_item_input summary_item_input searchAutocomplete"
                v-bind="field"
                :placeholder="fields.dropoff.placeholder"
                :autoCompleteString="orderData.dropoff"
                types=""
                id="summary_1_dropoff"
                maxlength="230"
                :disabled="!summaryDataObject.isEditable"
              >
              </vue-google-autocomplete>
            </Field>
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            v-else-if="checkField('dropoff') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Drop-Off Location:</span>
            <p
              class="summary_item_input text-background w-full !truncate break-all !rounded-none !border-0 !p-0 !pb-2 dark:!text-white"
            >
              {{ orderData.dropoff }}
            </p>
          </div>
          <div
            v-if="checkField('reqs') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
          >
            <span class="summary_item_title">Destinations & Requirements:</span>

            <Field
              type="text"
              v-model="orderData.reqs"
              class="summary-edit_item_input summary_item_input max-w-[300px] !truncate sm:max-w-[500px] md:max-w-[650px] lg:max-w-[200px]"
              name="requirments"
              placeholder="Destinations & Requirements"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            v-else-if="checkField('reqs') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Destinations & Requirements:</span>
            <p
              class="summary_item_input text-background w-full !truncate break-all !rounded-none !border-0 !p-0 !pb-2 dark:!text-white"
            >
              {{ orderData.reqs }}
            </p>
          </div>
          <div
            v-if="checkField('hours') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            :class="errors.hours ? 'error' : ''"
          >
            <span class="summary_item_title">Duration:</span>
            <div class="flex flex-row items-center justify-start gap-x-2">
              <Field
                type="text"
                v-model="orderData.hours"
                v-on:beforeinput="utils.isNumber($event)"
                class="summary-edit_item_input summary_item_input max-w-20 !truncate"
                name="hours"
                placeholder="Duration"
                :disabled="!summaryDataObject.isEditable"
              />
              <span class="summary_item_input_sibling ml-[2px]">hours</span>
            </div>
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
            v-else-if="checkField('hours') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Duration:</span>
            <div class="flex flex-row items-center justify-start gap-x-2">
              <p
                class="summary_item_input_sibling text-background max-w-20 break-all !rounded-none !border-0 !p-0 dark:!text-white"
              >
                {{ orderData.hours }}
              </p>
              <span class="summary_item_input_sibling ml-[2px]">hours</span>
            </div>
          </div>
          <div
            v-if="checkField('date_start') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 w-40 pb-0"
          >
            <span class="summary_item_title">Date:</span>
            <VueDatePicker
              ref="datePickerRef"
              placeholder="Date / Time"
              :preview-format="dateFormat"
              :disabled="!summaryDataObject.isEditable"
              :clearable="false"
              :format="dateFormat"
              :dark="mode === 'dark'"
              :light="mode === 'light'"
              name="date_start"
              v-model="inputDate"
              @internal-model-change="handleInternal"
              :min-date="minDate"
              :min-time="minTime"
              input-class-name="!w-40"
            >
              <template #right-sidebar>
                <div class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
                  <div
                    v-for="time in timeOptions"
                    :key="time"
                    @click="setTime(time)"
                    :class="[
                      'time-option cursor-pointer',
                      time === selectedTime ? 'time-selected' : ''
                    ]"
                  >
                    {{ time }}
                  </div>
                </div>
              </template>
            </VueDatePicker>
          </div>
          <div
            v-if="checkField('date_start') && !summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-40 shrink-0 pb-0"
          >
            <span class="summary_item_title">Date:</span>
            <p
              class="summary_item_input text-background max-w-[300px] break-all !rounded-none !border-0 !p-0 dark:!text-white sm:max-w-[500px] md:max-w-[650px] lg:max-w-[200px]"
            >
              {{ inputDate }}
            </p>
          </div>
          <div
            v-if="checkField('distance')"
            class="summary_item_wrapper mb-0 h-max w-max max-w-full pb-0"
          >
            <span class="summary_item_title">Distance:</span>
            <div class="flex flex-row items-center justify-start">
              <span class="summary_item_input_sibling">{{ orderData.distance }} KMS </span>
            </div>
          </div>

          <div
            v-if="checkField('car') && selectedCar"
            class="summary_item_wrapper mb-4 h-max w-min pb-0 md:mb-0 md:pb-[20px]"
          >
            <span class="summary_item_title">Car:</span>
            <div class="flex flex-row items-center justify-start">
              <span class="summary_item_input_sibling w-max"
                >{{ selectedCar.slug_class_name }}
              </span>
              <div v-if="!isMobile" class="popup_opened group relative ml-2 py-2">
                <div
                  class="absolute -right-[-60px] -top-[500px] z-40 hidden drop-shadow-popup group-hover:block"
                >
                  <car-popup-content />
                </div>
                <span class="summary_view_button cursor-pointer">View</span>
              </div>
              <div v-if="isMobile" class="ml-2 py-2 leading-[1]">
                <button @click="openModal()" class="summary_view_button">View</button>
              </div>
            </div>
          </div>
          <template v-if="props.disabled === 'false' && !paymentVisited">
            <button
              type="button"
              v-if="!summaryDataObject.isEditable"
              :class="props.customStyle == 'true' ? '!w-[260px]' : 'w-full'"
              class="summary_edit_button border-dark_main text-dark_main dark:border-main dark:text-main hover:border-main dark:hover:text-background ml-auto mr-0 h-16 w-full min-w-max px-3 py-1.5 max-md:hidden"
              @click="openEdit()"
            >
              Edit {{ route.name == 'success' ? 'Profile' : 'ride' }}
            </button>
            <button
              :disabled="isSubmitting"
              type="submit"
              v-if="summaryDataObject.isEditable"
              :class="props.customStyle == 'true' ? '!w-[260px]' : 'w-full'"
              class="summary_edit_button save ml-auto mr-0 h-16 w-full min-w-max border-none px-3 py-1.5 max-md:hidden"
            >
              Confirm
            </button>
          </template>
        </div>
      </div>
    </div>
    <slot></slot>
  </Form>
  <swipe-modal
    v-model="summaryDataObject.isModal"
    v-if="(isMobile && !mailCar) || !mailCar"
    contents-height="60vh"
    border-top-radius="16px"
    :contents-color="mode === 'dark' ? '#2B2D32' : '#FFFFFF'"
    tip-color="#CDCFD0"
    @close="closeModal()"
  >
    <car-popup-content />
  </swipe-modal>
</template>
<script setup>
import CarPopupContent from '@/components/CarPopupContent.vue'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import { useDatePicker } from '@/compose/datePicker'
import { useGoogleAddress } from '@/compose/googleAddress'
import { useMobile } from '@/compose/ismobile'
import { useCarsStore } from '@/stores/cars'
import { useMainStore } from '@/stores/main'
import { useOrderStore } from '@/stores/order'
import { useContactsStore } from '@/stores/contacts'
import swipeModal from '@takuma-ru/vue-swipe-modal'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import * as Sentry from '@sentry/browser'
import { collect } from 'collect.js'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { Field, Form } from 'vee-validate'
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import * as yup from 'yup'

const mainStore = useMainStore()
const carsStore = useCarsStore()
const orderStore = useOrderStore()
const contactsStore = useContactsStore()

const { orderData } = storeToRefs(useOrderStore())

const { mailCar, orderId } = storeToRefs(orderStore)
const { mode, isRequesting } = storeToRefs(mainStore)

const { selectedCar } = storeToRefs(carsStore)
const axios = inject('axios')
const utils = inject('utils')

const regexLink = inject('regexLink')
const regexIsHttps = inject('regexIsHttps')
const regexNameField = inject('regexNameField')

const route = useRoute()

const props = defineProps(['title', 'customStyle', 'disabled', 'classnames', 'prepare'])

const inputDate = computed({
  get: () => {
    return moment(orderData.value.date_start).format('YYYY-MM-DD HH:mm')
  },
  set: (value) => {
    orderStore.update({ date_start: moment(value).format('YYYY-MM-DD HH:mm') })
  }
})

const preparedFields = {
  serviceData: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration', 'car'],
  contactData: ['first_name', 'last_name', 'email', 'phone'],
  contact: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'car'],
  vehicle: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration'],
  payment: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration', 'car']
}

const preferredCountries = ['us', 'it', 'au', 'uk', 'in', 'ca', 'il', 'sg', 'ae']

const defaultSettingsVueTel = {
  mode: 'auto',
  autoFormat: false,
  preferredCountries: preferredCountries,
  inputClasses: 'input',
  defaultCountry: orderData.value.country_prefix ? orderData.value.country_prefix : '',
  dropdownOptions: {
    showFlags: true,
    showDialCodeInList: true,
    showDialCodeInSelection: true
  }
}

const countryChanged = (country) => {
  orderData.value.code = country.dialCode
  orderData.value.country_prefix = country.iso2
}

const pudoChanged = ref(false)
const getAddressDataSummary = (addressData, placeResultData) => {
  if (orderData.value.type_of_service === 'oneWayTransfer') {
    getAddressData(addressData, placeResultData)
    pudoChanged.value = true
  } else {
    getAddressData(addressData, placeResultData)
  }
}

const sourceFields = computed(() => {
  console.log(props.prepare)
  return props.prepare ?? route.name
})

const checkField = (field) => {
  return collect(preparedFields[sourceFields.value]).some(field) && orderData.value[field] != null
}

const getFields = computed(() => {
  const fields = preparedFields[sourceFields.value]
  const mapped = fields.filter((e) => checkField(e))

  const keys = collect(myObject).only(mapped).all()
  return yup.object(keys)
})

let summaryData = {
  isModal: false,
  isEditable: false,
  dateInputStyles: {
    pointerEvents: 'none'
  }
}
let summaryDataObject = reactive(summaryData)

const { fields, beforeInput, focusInput, getAddressData } = useGoogleAddress('summary')

const {
  datePickerRef,
  timeOptions,
  minDate,
  minTime,
  datePicker,
  handleInternal,
  dateFormat,
  initialDate
} = useDatePicker()

const selectedTime = ref('')

const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = moment(updatedDate).format('YYYY-MM-DD HH:mm')
    orderData.value.date_start = moment(updatedDate).format('YYYY-MM-DD HH:mm')
  }
  selectedTime.value = time

  datePickerRef.value.closeMenu()
}

const { isMobile } = useMobile()

const myObject = {
  email: yup
    .string()
    .required()
    .email()
    .max(230)
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  first_name: yup
    .string()
    .required()
    .max(50)
    .matches(regexNameField, { excludeEmptyString: true })
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  last_name: yup
    .string()
    .required()
    .max(50)
    .matches(regexNameField, { excludeEmptyString: true })
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  hours: yup.number().required().max(10),
  pickup: yup
    .string()
    .required()
    .max(230)
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      (value, ctx) => {
        if (orderData.value.ride_history) return true
        if (orderData.value.redis_id) return true
        return fields.value[ctx.path].valid
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  dropoff: yup
    .string()
    .required()
    .max(230)
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      (value, ctx) => {
        if (orderData.value.ride_history) return true
        if (orderData.value.redis_id) return true
        return fields.value[ctx.path].valid
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true })
}

const changeButtonColor = (mode) => {
  if (mode === 'light') {
    summaryDataObject.dateInputStyles = {
      pointerEvents: 'all',
      color: 'rgb(45 132 35 / 1)',
      borderBottom: '1px solid rgb(45 132 35 / 1)'
    }
  } else {
    summaryDataObject.dateInputStyles = {
      pointerEvents: 'all',
      color: 'rgb(204 242 200 / 1)',
      borderBottom: '1px solid rgb(204 242 200 / 1)'
    }
  }
}

const openEdit = () => {
  summaryDataObject.isEditable = true
  changeButtonColor(mode.value)
}
watch(mode, (newMode) => {
  changeButtonColor(newMode)
})
const onSubmit = async (values) => {
  values.code = orderData.value.code
  values.phone = orderData.value.phone
  values.country_prefix = orderData.value.country_prefix
  values.date_start = orderData.value.date_start
  values.contact_id = orderData.value.contact_id
  values.type_of_service = orderData.value.type_of_service

  contactsStore.updateContact(values)

  if (orderData.value.type_of_service === 'hourlyAsDirected' && route.name != 'success') {
    values.distance = values.hours * 20
  }

  orderStore.update(values)

  if (
    orderId.value !== 'null' &&
    orderId.value &&
    (route.name != 'success' || orderData.value.consulting)
  ) {
    isRequesting.value = true
    await axios
      .post('/orders/update/orderSummary/' + orderId.value, values)
      .then(function (response) {
        if (
          response.data.data.cars !== undefined &&
          response.data.data.cars !== null &&
          response.data.data?.cars.length > 0
        ) {
          let new_cars = response.data.data.cars
          carsStore.update(new_cars)
        }
        if (response.data.data.order !== undefined && response.data.data.order !== null) {
          let data_order = response.data.data.order
          orderStore.updateOrder(data_order)
          orderStore.updateOrderId(data_order.id)
          orderStore.update({ distance: data_order.distance })

          if (pudoChanged.value) {
            axios.post('/fields/search/' + orderId.value, {}).then(() => {})
          }
          isRequesting.value = false
        }
      })
      .catch(function (error) {
        isRequesting.value = false
        Sentry.captureException(error)
      })
  }

  if (
    (orderData.value.deal_id || orderData.value.type_of_service == 'toursRoadshows') &&
    route.name == 'success'
  ) {
    if (orderData.value.type_of_service == 'toursRoadshows') {
      values['lead_id'] = orderData.value.lead_id
    }

    isRequesting.value = true

    values.phone = `${values.phone === null ? '' : values.phone}`

    await axios
      .post('/success/editContact', values)
      .then((/*response*/) => {
        // console.log(response);
        isRequesting.value = false
      })
      .catch((error) => {
        isRequesting.value = false
        Sentry.captureException(error)
      })
  }

  summaryDataObject.isEditable = false
  summaryDataObject.dateInputStyles = {
    pointerEvents: 'none',
    color: 'unset',
    borderCollor: 'transparent'
  }
}

onMounted(() => {})

const openModal = () => {
  summaryDataObject.isModal = true
  document.querySelector('html').style.touchAction = 'none'
  document.body.style.touchAction = 'none'
  document.querySelector('html').style.overflowY = 'hidden'
  document.body.style.overflowY = 'hidden'
  document.querySelector('html').style.overscrollBehavior = 'none'
  document.body.style.overscrollBehavior = 'none'
}

const closeModal = () => {
  summaryDataObject.isModal = false
  document.querySelector('html').style.touchAction = 'auto'
  document.body.style.touchAction = 'auto'
  document.querySelector('html').style.overflowY = 'auto'
  document.body.style.overflowY = 'auto'
  document.querySelector('html').style.overscrollBehavior = 'auto'
  document.body.style.overscrollBehavior = 'auto'
}
</script>
